import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Slider from '../components/slider'
import Link from '../components/link'

const HomePage = ({ data: { Slides, Testimonials}}) => (
  <Layout>
    <SEO title="Bag Center" />

    <section className="banner">
      <div className="banner-content">
        <Slider settings={{
          className: 'banner-slides',
          adaptiveHeight: true
        }}>
          {Slides.nodes.map((slide, index) => (
            <div
              className="banner-slide"
              key={ index }
            >
              <h2 className="banner-slide--heading" dangerouslySetInnerHTML={{ __html: slide.text }} />
              {slide.buttonText && slide.buttonLink && (
                <Link
                  className="banner-slide--button"
                  to={ slide.buttonLink }
                >
                  { slide.buttonText }
                </Link>
              )}
            </div>
          ))}
        </Slider>
      </div>
    </section>

    <section className="companies">
      <img
        src="/images/companies/logo_aplus.png"
        srcSet="/images/companies/logo_aplus.png 1x, /images/companies/logo_aplus@2x.png 2x"
        alt="A+ Company logo"
      />

      <img
        src="/images/companies/logo_dc.png"
        srcSet="/images/companies/logo_dc.png 1x, /images/companies/logo_dc@2x.png 2x"
        alt="DC Company logo"
      />

      <img
        src="/images/companies/logo_sage.png"
        srcSet="/images/companies/logo_sage.png 1x, /images/companies/logo_sage@2x.png 2x"
        alt="Sage Company logo"
      />
    </section>

    {false && (
    <section className="testimonials">
      <h2 className="page-heading">
        Testimonials
      </h2>

      <Slider settings={{
        className: 'testimonials-slides',
        adaptiveHeight: true
      }}>
        {Testimonials.nodes.map((slide, index) => (
          <div
            className="testimonial"
            key={ index }
          >
            <p className="testimonial-quote">
              { slide.text }
            </p>
            <h3 className="testimonial-author">
              { slide.author }
            </h3>
          </div>
        ))}
      </Slider>
    </section>
    )}
  </Layout>
)

export const query = graphql`
  query HomePageQuery {
    Slides: allBannerSlidesYaml {
      nodes {
        text
        buttonText
        buttonLink
      }
    }
    Testimonials: allTestimonialsYaml {
      nodes {
        author
        text
      }
    }
  }
`

export default HomePage

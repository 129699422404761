import React from 'react'

import Link from './link'
import HeaderMenu from './headerMenu'

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      menuOpened: false
    }

    this.onOpen = this.onOpen.bind(this)
    this.onClose = this.onClose.bind(this)
  }

  onOpen() {
    this.setState({
      ...this.state,
      menuOpened: true
    }, () => document.body.style.overflow = 'hidden')
  }

  onClose() {
    this.setState({
      ...this.state,
      menuOpened: false
    }, () => document.body.style.overflow = '')
  }

  render() {
    return (
      <header className="header">
        <div className="header-content">
          <Link to="/">
            <img
              className="header-logo"
              width="120"
              src="/images/logo.png"
              alt="Bag Center Logo"
            />
          </Link>

          <div
            className="header-hamburger"
            onClick={ this.onOpen }
          >
            <span/>
          </div>

          <HeaderMenu
            menuOpened={ this.state.menuOpened }
            onClose={ this.onClose }
          />
        </div>
      </header>
    )
  }
}

export default Header

import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import FooterMenu from './footerMenu'

const Footer = () => (
  <StaticQuery
    query={graphql`
      query FooterMenus {
        Main: allMenusYaml(filter: {menu: {eq: "footer"}}) {
          nodes {
            items {
              name
              url
            }
          }
        }
        Bags: allBagsYaml {
          nodes {
            name
            slug
          }
        }
      }
    `}
    render={data => {
      const { Main, Bags } = data;
      const bags = Bags.nodes.map(node => {
        node.url = '/bags/' + node.slug;
        return node
      });
      return (
        <footer className="footer">
          <div className="footer-content">
            <img
              className="footer-logo"
              width="120"
              src="/images/logo.png"
              alt="Bag Center Logo"
            />

            <FooterMenu
              items={ Main.nodes[0].items }
              isMainMenu={ true }
            />
            <FooterMenu
              items={ bags.slice(0,5) }
            />
            <FooterMenu
              items={ bags.slice(5,10) }
            />
          </div>
        </footer>
      )
    }}
  />
)

export default Footer
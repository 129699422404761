import React from 'react'
import PropTypes from 'prop-types'

import Link from './link'

const FooterMenu = ({ items, isMainMenu }) => (
  <div className={ `footer-menu ${isMainMenu ? 'footer-menu__main' : ''}` }>
    {items.map((item, index) => (
      <Link
        className={ isMainMenu ? 'footer-menu--item' : 'footer-submenu--item' }
        to={ item.url }
        key={ index }
      >
        { item.name }
      </Link>
    ))}
  </div>
)

FooterMenu.defaultProps = {
  isMainMenu: false
}

FooterMenu.propTypes = {
  items: PropTypes.array.isRequired,
  isMainMenu: PropTypes.bool.isRequired
}

export default FooterMenu
import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Link from './link'

const HeaderMenu = ({ menuOpened, onClose }) => (
  <StaticQuery
    query={graphql`
      query HeaderMenu {
        Menu: allMenusYaml(filter: {menu: {eq: "header"}}) {
          nodes {
            items {
              name
              url
              bagsSubmenu
            }
          }
        }
        BagsSubmenu: allBagsYaml {
          nodes {
            id
            name
            slug
          }
        }
      }
    `}
    render={({ Menu, BagsSubmenu }) => (
      <ul className={ `header-menu ${menuOpened ? 'opened' : ''}` }>
        {Menu.nodes[0].items.map((item, index) => (
          <li className="header-menu--item" key={ index }>
            <Link
              activeClassName="active"
              to={ item.url }
            >
              { item.name }
            </Link>

            {item.bagsSubmenu && (
              <ul className="header-submenu" key="bags-submenu">
                {BagsSubmenu.nodes.map(item => (
                  <li className="header-submenu--item" key={ item.id }>
                    <Link
                      activeClassName="active"
                      to={ `/bags/${item.slug}` }
                    >
                      { item.name }
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}

        <div
          className="close-button header-menu--close"
          onClick={ onClose }
        />
      </ul>
    )}
  />
)

HeaderMenu.defaultProps = {
  menuOpened: false
}

HeaderMenu.propTypes = {
  menuOpened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

export default HeaderMenu
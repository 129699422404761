import React from 'react'
import PropTypes from 'prop-types'

import Header from './header'
import Footer from './footer'

import '../styles/styles.scss'

const Layout = ({ children }) => (
  <div className="content-wrapper">
    <Header />
    <main className="content">{ children }</main>
    <Footer />
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

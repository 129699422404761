import React from 'react'
import PropTypes from 'prop-types'
import { Link as GatsbyLink } from 'gatsby'

class Link extends React.Component {
  render() {
    const { children, to, activeClassName, i18n, ...other } = this.props
    const internal = /^\/(?!\/)/.test(to)

    if (internal) {
      return (
        <GatsbyLink
          to={ to }
          activeClassName={ activeClassName }
          { ...other }
        >
          { children }
        </GatsbyLink>
      )
    }
    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={ to }
        { ...other }
      >
        { children }
      </a>
    )
  }
}

Link.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
  activeClassName: PropTypes.string
}

export default Link